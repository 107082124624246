
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': window.Laravel.csrfToken
	},
	cache: true
});
jQuery.fn.load = function(callback){ $(window).on("load", callback) };
$.fn.load = function(callback){ $(window).on("load", callback) };
require('./lightslider');
require('./lpcms');